.mc_embed_signup {
	.newsletter__title {
        @extend .heading-md;
		color: $color-highlight;
		margin: 0 auto 35px;
		max-width: 600px;
		text-align: center;
		@include media-breakpoint-up(lg) {
			margin: 0 0 35px;
			max-width: 700px;
			text-align: left;
		}
    }

	.newsletter__wrapper {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		@include media-breakpoint-up(lg) {
			justify-content: flex-start;
			flex-direction: row;
			align-items: unset;
        }
	}
	form {
		@include media-breakpoint-up(lg) {
			text-align: left !important;
        }
	}
	.mc-field-group {
		display: flex;
		flex-direction: column;
		input[type="email"] {
			height: 50px;
			width: fit-content;
			padding: 0 15px;
			background-color: $color-bg-base;
			font-size: 1.6rem;
			color: $color-secondary;
			border: 2px solid $color-secondary;
			border-radius: 23px;
			outline: 0;
			margin-bottom: 20px;
			text-align: center;
			font-family: $font-family-base;
			&::placeholder {
				color: $color-secondary;
				opacity: 0.8;
			}
			@include media-breakpoint-up(lg) {
				padding: 0 35px;
				text-align: left;
				height: 91px;
				border-radius: 42px;
				font-size: $body-font-size;
				margin-right: 70px;
				margin-bottom: 0;
			}
		}
	}
   	.button {
		height: 50px;
		width: fit-content;
        padding: 0 25px;
        background-color: $color-secondary;
        font-size: $font-size-sm;
        color: $color-primary;
        border: 2px solid $color-secondary;
		border-radius: 23px;
		text-transform: uppercase;
		@include media-breakpoint-up(lg) {
			padding: 0 50px;
			height: 91px;
			border-radius: 42px;
			font-size: $body-font-size;
        }
		@include focus-visible($color-secondary);
    }
}

.popup {
	.newsletter__title {
		color: $color-primary;
		margin: 0 auto 20px;
		text-align: center;
		max-width: 180px;
		@include media-breakpoint-up(lg) {
			margin: 0 auto 50px;
			max-width: 560px;
		}
	}
	.mc-field-group {
		input[type="email"] {
			background-color: $color-bg-secondary !important;
			border: 2px solid $color-primary!important;
			color: $color-primary!important;
			max-width: 200px;
			&::placeholder {
				color: $color-primary!important;
			}
			@include media-breakpoint-up(lg) {
				max-width: unset;
				margin-right: 30px !important;
			}
		}
	}
	.button {
        background-color: $color-bg-base!important;
        color: $color-secondary!important;
        border: 2px solid $color-bg-base!important;
		&:focus-visible {
			outline: max(1px, 0.15em) solid $color-primary !important;
			outline-offset: 0.25em !important;
			box-shadow: none;
		}
    }
}