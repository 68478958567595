.heading-lg {
	font-size: 3.3rem;
	line-height: 1;
	font-weight: $font-weight-base;
	// hyphens: auto;
	@include media-breakpoint-up(lg) {
		line-height: 1.2;
		font-size: $font-size-lg;
	}
}

.heading-md {
	font-size: 2.1rem;
	line-height: 1.3;
	font-weight: $font-weight-base;
	// hyphens: auto;
	@include media-breakpoint-up(sm) {
		font-size: 3rem;
	}
	@include media-breakpoint-up(lg) {
		font-size: $font-size-md;
	}
}

.heading-sm {
	font-size: 1rem;
	line-height: 1;
	font-weight: $font-weight-base;
	@include media-breakpoint-up(lg) {
		font-size: $font-size-sm;
	}
}

.txt-body {
	font-size: 1.4rem;
	font-weight: $font-weight-base;
	line-height: $line-height-base;
	@include media-breakpoint-up(sm) {
		font-size: $font-size-sm;
	}
	@include media-breakpoint-up(lg) {
		font-size: $body-font-size;
	}
}
