// Typography
$font-size-base: 62.5%;
$font-family-base: 'Helvetica Rounded LT W02 Bold', sans-serif;

// Headlines
$font-size-lg: 6rem;
$font-size-md: 3.8rem;
$font-size-sm: 1.8rem;

// Body text
$body-font-size: 2.4rem;

// Line height
$line-height-base: 1.5;

// Font weight
$font-weight-base: 400;

// Color system
$color-black: #000;
$color-white: #FFF;

$color-primary: #012478;
$color-secondary: #EBC3A8;
$color-highlight: #EBBB4F;

$color-bg-base: #0D0956;
$color-bg-primary: #EBBB4F;
$color-bg-secondary: #E9C8B5;

$grid-breakpoints: (
	xs: 0,
	sm: 576px,
	md: 768px,
	lg: 992px,
	xl: 1200px,
	xxl: 1400px,
) !default;

@include _assert-ascending($grid-breakpoints, '$grid-breakpoints');
@include _assert-starts-at-zero($grid-breakpoints, '$grid-breakpoints');