@mixin hover() {
	&:hover {
	  @content;
	}
}

@mixin hover-focus() {
	&:hover,
	&:focus {
	  @content;
	}
}

@mixin hover-focus-active {
	&:hover,
	&:focus,
	&:active {
	  @content;
	}
}

@mixin focus-visible ($outline) {
	&:focus-visible {
		outline: max(1px, 0.15em) solid $outline;
		outline-offset: 0.25em;
		box-shadow: none;
	}
}

@mixin focus-visible-dotted ($outline) {
	&:focus-visible {
		outline: max(2px, 0.15em) dotted $outline;
		outline-offset: 0.1em;
		box-shadow: none;
	}
}

@mixin button ($color, $bg-color) {
	font-family: inherit;
	font-weight: $font-weight-base;
	color: $color;
	border: 0;
	border-radius: 23px;
	height: 50px;
	padding: 0 25px;
	font-size: 1.4rem;
	line-height: 1.3;
	background-color: $bg-color;
	vertical-align: middle;
	user-select: none;
	cursor: pointer;
	display: flex;
	justify-content: center;
	align-items: center;
	width: max-content;
	@include media-breakpoint-up(md) {
		display: block;
		border-radius: 42px;
		height: 91px;
		padding: 35px 40px;
		font-size: $body-font-size;
		line-height: 1;
    }
}
