.d-section {
	position: relative;
}

.d-section__inner {
	width: 100%;
	grid-column: 1 / 21;
}

.d-section--hero {
	figure {
		width: 100%;
		height: 100%;
		margin: 0;
	}
	iframe,
	img {
		width: 100%;
		height: 100%;
		object-fit: cover;
		object-position: center;
	}
	.d-section__inner {
		display: flex;
		justify-content: center;
		height: 50vh;
		@include media-breakpoint-up(sm) {
			height: 80vh;
		}
		@include media-breakpoint-up(lg) {
			height: 100vh;
		}
	}
	//lottie player
	.text-wave {
		position: absolute;
		bottom: -6px;
		left: 0;
	}
}

.home .d-section--hero .d-section__inner {
	display: flex;
	justify-content: center;
	height: 100vh;
	@include media-breakpoint-up(sm) {
		height: 130vh;
	}
}

.d-section--menu {
	background-color: $color-bg-base;
	padding-top: 50px;
	@include media-breakpoint-up(sm) {
		padding-top: 100px;
	}
	@media only screen and (min-width: 1500px) {
		padding-top: 150px;
	}
	.d-section__inner {
		overflow: hidden;
	}
	.container {
		position: relative;
	}

	.c-text {
		@extend .heading-md;
		color: $color-secondary;
		padding: 0 10%;
		margin: 0 auto;
		text-align: center;
		padding-bottom: 50px;
		@include media-breakpoint-up(sm) {
			padding: 0 20%;
			padding-bottom: 100px;
		}
		@include media-breakpoint-up(lg) {
			padding: 0 30%;
			padding-bottom: 100px;
		}
		@media only screen and (min-width: 1500px) {
			padding-bottom: 150px;
		}

		a {
			color: $color-bg-primary;
		}
	}
	svg {
		display: block;
		margin-bottom: -1px;
	}
}

.d-section--newsletter {
	background-color: $color-bg-base;
	padding-top: 50px;
	@include media-breakpoint-up(sm) {
		padding-top: 100px;
	}
	@include media-breakpoint-up(lg) {
		padding-top: 200px;
	}
	.d-section__inner {
		grid-column: 3 / 19;
	}
	.d-section__divider {
		width: 100%;
		position: absolute;
		top: -5.6vw;
		left: 0;
		img {
			width: 100%;
			height: auto;
			object-fit: contain;
		}
	}
}

.d-section--contact {
	background-color: $color-bg-primary;
	padding: 75px 0;
	@include media-breakpoint-up(sm) {
		padding: 100px 0;
	}
	@include media-breakpoint-up(lg) {
		padding: 200px 0;
	}
	@media only screen and (min-width: 1500px) {
		padding: 300px 0 200px;
	}

	.d-section__swirls {
		width: max(160px, 15vw);
		position: absolute;
		top: -10vw;
		left: -5vw;
		z-index: 999;
	}
	.d-section__divider {
		width: 83px;
		position: absolute;
		top: 0;
		right: 0;
		z-index: 999;
		@include media-breakpoint-up(lg) {
			width: 150px;
		}
		img {
			width: 100%;
			height: auto;
			object-fit: contain;
		}
	}

	.d-section__content {
		grid-column: 3 / 19;
		margin-bottom: 30px;
		@include media-breakpoint-up(lg) {
			grid-column: 3 / 11;
			margin-bottom: 0;
			align-self: center;
		}
		.heading-lg,
		.address {
			margin-bottom: 30px;
			@include media-breakpoint-up(lg) {
				margin-bottom: 50px;
			}
		}
		.address {
			@include focus-visible($color-primary);
			.heading-md div {
				@include hover {
					text-decoration: underline;
				}
			}
		}
		.email {
			@extend .txt-body;
			margin-bottom: 12px;
			a {
				color: inherit;
				@include focus-visible-dotted($color-primary);
				@include hover {
					text-decoration: underline;
				}
			}
		}
	}

	.d-section__map {
		grid-column: 3 / 19;
		@include media-breakpoint-up(lg) {
			grid-column: 12 / 19;
		}
		a {
			display: block;
			@include focus-visible($color-primary);
		}
		img {
			width: 100%;
			height: 100%;
			object-fit: contain;
		}
	}
}

.d-section--about {
	background-color: $color-bg-secondary;
	padding: 75px 0;
	@include media-breakpoint-up(lg) {
		padding-top: 0;
		padding-bottom: 200px;
	}

	.d-section__divider {
		width: 83px;
		position: absolute;
		top: -12.5vw;
		left: -10vw;
		z-index: 999;
		@include media-breakpoint-up(lg) {
			width: 150px;
			left: 0;
		}
		img {
			width: 100%;
			height: auto;
			object-fit: contain;
		}
	}
	.d-section__sprinkles {
		width: max(160px, 15vw);
		position: absolute;
		top: -20vw;
		right: -10vw;
		z-index: 999;
		@include media-breakpoint-up(md) {
			top: -10vw;
			right: -5vw;
		}
		img {
			width: 100%;
			height: auto;
			object-fit: contain;
		}
	}
	.d-section__divider-arrow {
		width: max(50px, 2.5vw);
		position: absolute;
		bottom: 0;
		right: 0;
		z-index: 9;
		@include media-breakpoint-up(md) {
			width: max(100px, 2.5vw);
			right: 1vw;
		}
		img {
			width: 100%;
			height: auto;
			object-fit: contain;
		}
	}
	.d-section__content {
		grid-column: 3 / 19;
		margin-bottom: 30px;
		z-index: 999;
		@include media-breakpoint-up(lg) {
			grid-column: 4 / 10;
			margin-bottom: 0;
			align-self: center;
		}
		.heading-lg {
			margin-bottom: 16px;
			@include media-breakpoint-up(lg) {
				margin-bottom: 30px;
			}
		}
	}
	.d-section__stack {
		grid-column: 2 / 19;
		height: 100%;
		position: relative;
		z-index: 999;
		margin-top: 50px;
		@include media-breakpoint-up(lg) {
			grid-column: 12 / 21;
			margin-top: 0;
		}
	}

	.d-section__stack-cards {
		position: relative;
		top: 0;
		left: 0;
		width: 100%;
		padding-top: 100%;
		cursor: pointer;
		z-index: 99;
	}
}

.d-section--followus {
	background-color: $color-bg-primary;

	.d-section__swirls {
		width: max(160px, 25vw);
		position: absolute;
		top: 0;
		right: -8vw;
		z-index: 999;
	}

	.d-section__inner {
		padding-top: 50px;
		@include media-breakpoint-up(md) {
			padding-top: 150px;
		}

		text-align: center;
		z-index: 999;
		overflow: hidden;
		.heading-lg {
			margin-bottom: 10px;
		}

		p {
			@extend .heading-md;
			margin-bottom: 24px;
			@include media-breakpoint-up(lg) {
				margin-bottom: 34px;
			}
		}
	}

	.d-section__bg {
		position: absolute;
		width: 100%;
		top: -15vw;
		z-index: 99;
		@include media-breakpoint-up(lg) {
			top: -25vw;
		}
		img {
			width: 100%;
			height: 100%;
			object-fit: contain;
			object-position: center;
		}
		& + .container .d-section__inner {
			padding-top: 50px;
		}
	}
}

.d-section--404 {
	width: 100%;
	height: 100vh;

	img {
		width: 100%;
		height: 100%;
		object-fit: cover;
	}
	.container {
		position: absolute;
		top: 50%;
		left: 50%;
		z-index: 99;
		transform: translate(-50%, -50%);
	}

	.d-section__inner {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		padding-top: 10vw;
		.heading-lg {
			margin-bottom: 12px;
			@include media-breakpoint-up(lg) {
				margin-bottom: 24px;
			}
		}
		.txt-body {
			margin-bottom: 30px;
			@include media-breakpoint-up(lg) {
				margin-bottom: 60px;
			}
		}
	}
}
