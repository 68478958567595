.c-footer {
	background-color: $color-bg-base;
	margin-top: -2px;
	&__inner {
		grid-column: 3 / 19;
		display: flex;
		flex-direction: column;
		align-items: center;
		padding: 25px 0;
		@include media-breakpoint-up(xl) {
			margin: 62px;
			flex-direction: row;
		}
	}
}

.c-footer__logo {
	margin: 38px;
	margin-bottom: 20px;
	grid-column: 3 / 19;
	display: flex;
	justify-content: center;
	@include media-breakpoint-up(lg) {
		margin: 120px 0 0;
	}
	img {
		@extend .spinning;
		width: max(95px, 10vw);
		height: max(95px, 10vw);
		object-fit: contain;
		@include media-breakpoint-up(lg) {
			width: max(160px, 10vw);
			height: max(160px, 10vw);
		}
	}
}

.c-footer__creds,
.c-footer__legal {
	color: $color-secondary;
	font-size: 1.2rem;
	text-align: center;
	@include media-breakpoint-up(lg) {
		font-size: $font-size-sm;
		line-height: $line-height-base;
	}
	@include media-breakpoint-up(xl) {
		text-align: left;
	}
	span {
		margin-bottom: 12px;
		white-space: nowrap;
		@include media-breakpoint-up(xl) {
			margin-bottom: 0;
		}
	}
	a {
		color: inherit;
		@include focus-visible-dotted($color-secondary);
		transition: opacity 250ms ease-in-out;
	}
}

.c-footer__legal {
	text-transform: uppercase;
	width: 100%;
	span { display: block; }
	@include media-breakpoint-up(xl) {
		width: 40%;
	}
}

.c-footer__creds {
	display: flex;
	flex-direction: column;
	width: 100%;
	@include media-breakpoint-up(xl) {
		width: 60%;
	}
	@include media-breakpoint-up(xl) {
		flex-direction: row;
		justify-content: space-between;
	}
}