.c-arrowdown {
	display: none; // hide on default page
	width: 60px;
	height: 60px;
	border: 0;
	background-color: transparent;
	bottom: 100px;
	@include media-breakpoint-up(md) {
		width: 80px;
		height: 80px;
		bottom: 17.5vw;
	}
	@media only screen and (min-width: 1500px) {
		width: 120px;
		height: 120px;
		bottom: 20vw;
	}

	position: absolute;
	cursor: pointer;
	img {
		width: 100%;
		height: 100%;
		object-fit: contain;
	}

	@include focus-visible($color-primary);
}

.home .c-arrowdown {
	display: block;
}
