.c-hero-title {
	width: 100%;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	.title {
		padding: 0 15vw;
		height: auto;
		@include media-breakpoint-up(md) {
			height: 200px;
		}
		@media only screen and (min-width: 1500px) {
			padding: 0 20vw;
		}

		img {
			width: 100%;
			height: auto;
			object-fit: contain;
		}
	}
	.subtitle {
		padding: 0 7.5vw;
		@media only screen and (min-width: 1500px) {
			padding: 0 10vw;
		}

		.text-on-path {
			line-height: 1.3;
			font-size: 6.5rem;
			font-weight: $font-weight-base;
			// hyphens: auto;
			fill: $color-white;

			@include media-breakpoint-up(md) {
				line-height: 1.3;
				font-size: 3.5rem;
			}
		}
		.path {
			fill: transparent;
			width: 100%;
			height: 100px;
			@include media-breakpoint-up(md) {
				height: 200px;
			}
			@media only screen and (min-width: 1500px) {
				height: 300px;
				margin-top: 2vw;
			}
		}
	}

	.desktop-subtitle {
		display: none;
		@include media-breakpoint-up(md) {
			display: block;
		}
	}

	.mobile-subtitle {
		display: block;
		@include media-breakpoint-up(md) {
			display: none;
		}
	}

	.mobile-subtitle-2 {
		transform: translateY(-60px);
	}
}
