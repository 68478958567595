.c-hamburger {
	--hamburger-padding-x: 15px;
	--hamburger-padding-y: 15px;
	--hamburger-layer-color: #ebc3a8;
	--hamburger-layer-width: 20px;
	--hamburger-layer-height: 2px;
	--hamburger-layer-spacing: 4px;

	@include media-breakpoint-up(md) {
		--hamburger-layer-width: 33px;
		--hamburger-layer-spacing: 6px;
	}

	display: inline-block;
	cursor: pointer;
	pointer-events: all;
	font: inherit;
	color: inherit;
	text-transform: none;
	padding: var(--hamburger-padding-y) var(--hamburger-padding-x);
	background-color: $color-bg-base;
	border-radius: 50%;
	border: 0;
	margin: 0 calc(var(--hamburger-padding-x) * -1) 0 0;
	overflow: visible;
	position: relative;
	position: absolute;
	top: 30px;
	left: 40px;
	z-index: 9;
	width: 50px;
	height: 50px;
	@include media-breakpoint-up(md) {
		width: 66px;
		height: 66px;
	}

	@include focus-visible(var(--hamburger-layer-color));

	&.is-active {
		.c-hamburger-inner {
			width: var(--hamburger-layer-width);
			transform: rotate(45deg);
			transition-delay: 0.12s;
			transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);

			&::before {
				width: var(--hamburger-layer-width);
				top: 0;
				opacity: 0;
				transition: top 0.075s ease, opacity 0.075s 0.12s ease;
			}

			&::after {
				width: var(--hamburger-layer-width);
				bottom: 0;
				transform: rotate(-90deg);
				transition: bottom 0.075s ease, transform 0.075s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1);
			}
		}
	}
}

#nav-toggle-close {
	z-index: 99;
	top: 30px;
	left: unset;
	right: 10vw;
	padding: 0;
	display: flex;
	align-items: center;
	justify-content: center;

	@include media-breakpoint-down(md) {
		right: 12vw;
	}

	@include media-breakpoint-down(sm) {
		right: 15vw;
	}
}

.c-hamburger-box {
	width: var(--hamburger-layer-width);
	height: calc(var(--hamburger-layer-height) * 2 + var(--hamburger-layer-spacing) * 2);
	display: block;
	position: relative;
	z-index: 1000;
}

.c-hamburger-inner {
	z-index: 1000;
	display: block;
	top: 50%;
	margin-top: calc(var(--hamburger-layer-height) / -2);

	&,
	&::before,
	&::after {
		width: var(--hamburger-layer-width);
		height: var(--hamburger-layer-height);
		background-color: var(--hamburger-layer-color);
		position: absolute;
		right: 0;
		transition-property: transform;
		border-radius: 1.6px;
	}

	&::before,
	&::after {
		content: '';
		display: block;
	}

	&::before {
		top: calc((var(--hamburger-layer-spacing) + var(--hamburger-layer-height)) * -1);
		transition: top 0.075s 0.12s ease, opacity 0.075s ease;
	}

	&::after {
		bottom: calc((var(--hamburger-layer-spacing) + var(--hamburger-layer-height)) * -1);
		transition: bottom 0.075s 0.12s ease, transform 0.075s cubic-bezier(0.55, 0.055, 0.675, 0.19);
	}
}

// Minimize the amount of animation or motion.
@media (prefers-reduced-motion: reduce) {
	.c-hamburger-inner,
	.c-hamburger-inner::before,
	.c-hamburger-inner::after {
		transition-duration: 0s !important;
		transition-delay: 0s !important;
	}
}
