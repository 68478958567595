.c-header {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	z-index: 99;
	.container {
		display: flex;
		justify-content: center;
	}
}

.c-header__logo {
	width: max(100px, 8vw);
	height: max(100px, 8vw);
	margin: 30px 38px 38px;
	position: absolute;

	@extend .spinning;

	@media only screen and (min-width: 1500px) {
		width: max(100px, 5vw);
		height: max(100px, 5vw);
		margin: 30px 62px 62px;
	}

	img {
		width: 100%;
		height: 100%;
		object-fit: contain;
	}
}

.home .c-header__logo {
	width: max(160px, 10vw);
	height: max(160px, 10vw);
	margin: 38px;

	@media only screen and (min-width: 1500px) {
		width: max(160px, 12.5vw);
		height: max(160px, 12.5vw);
		margin: 62px;
	}
}
