html {
	-webkit-font-smoothing: antialiased;
	font-size: $font-size-base;
}

body {
	position: relative;
	font-family: $font-family-base;
	color: $color-primary;
	overflow-x: hidden;
}

// Placeholder form input verticaal centreren op fysiek mobiel apparaat
input::-webkit-input-placeholder,
textarea::-webkit-input-placeholder {
	line-height: normal;
}

h1,
h2,
h3,
p {
	margin: 0;
}

lottie-player {
	@media (prefers-reduced-motion: reduce) {
		opacity: 0;
	}
}

.icon {
	display: inline-block;
	width: 1em;
	height: 1em;
	stroke-width: 0;
	stroke: currentColor;
	fill: currentColor;
}

.lazyload,
.lazyloading {
	opacity: 0;
}

.lazyloaded {
	opacity: 1;
	transition: opacity 0.3s;
}

img[data-sizes='auto'] {
	display: block;
	width: 100%;
	height: auto;
}

a {
	color: $color-primary;
	display: inline-block;
	cursor: pointer;
	text-decoration: none;
}

.screen-reader-text {
	width: 1px;
	height: 1px;
	overflow: hidden;
	clip: rect(1px, 1px, 1px, 1px);
	clip-path: inset(50%);
	white-space: nowrap;
}

.reset-list {
	list-style: none;
	padding: 0;
	margin: 0;
}

.for-desktop-only {
	display: none;
	@include media-breakpoint-up(md) {
		display: block;
	}
}

.for-mobile-only {
	display: block;
	@include media-breakpoint-up(md) {
		display: none;
	}
}

.button {
	@include button($color-secondary, $color-primary);
	margin: 0 auto;
	text-transform: uppercase;
	font-size: $font-size-sm;
	@include focus-visible($color-primary);
}

.main-content {
	overflow: hidden;
}

.has-no-slider + .d-section--contact {
	.d-section__divider {
		display: none;
	}
}
