//spinning logo animation
.spinning {
	animation-name: spinning;
	animation-duration: 15s;
	animation-iteration-count: infinite;
	transform-origin: center;
	animation-timing-function: linear;

	@media (prefers-reduced-motion: reduce) {
		animation: none;
	}
}

//dancing characters animation

.menu-item a:hover {
	.word .char {
		animation: bounce1 0.75s infinite linear 0.2s;
		animation-delay: 0s;
		display: inline-block;

		@media (prefers-reduced-motion: reduce) {
			animation: none;
		}
	}
	.char:nth-child(4n + 1) {
		animation-delay: 0.1s;
		color: #e9c8b5;
	}
	.char:nth-child(4n + 2) {
		animation-delay: 0.2s;
		color: #ebbb4f;
	}
	.char:nth-child(4n + 3) {
		animation-delay: 0.25s;
		color: #f1b9bc;
	}

	.char:nth-child(4n + 4) {
		animation-delay: 0.3s;
		color: #3f9297;
	}
}

.button:hover {
	.word .char {
		animation: bounce1 0.75s infinite linear 0.2s;
		animation-delay: 0s;
		display: inline-block;

		@media (prefers-reduced-motion: reduce) {
			animation: none;
		}
	}
	.char:nth-child(4n + 1) {
		animation-delay: 0.1s;
	}
	.char:nth-child(4n + 2) {
		animation-delay: 0.2s;
	}
	.char:nth-child(4n + 3) {
		animation-delay: 0.3s;
	}
}

.c-footer__creds a:hover {
	.word .char {
		animation: bounce2 1s infinite linear 0.2s;
		animation-delay: 0s;
		display: inline-block;
		margin-bottom: 0;

		@media (prefers-reduced-motion: reduce) {
			animation: none;
		}
	}
	.char:nth-child(4n + 1) {
		animation-delay: 0.1s;
	}
	.char:nth-child(4n + 2) {
		animation-delay: 0.2s;
	}
	.char:nth-child(4n + 3) {
		animation-delay: 0.3s;
	}
}

//stack images animation
.d-section__stack-cards .stack-card {
	border-radius: 50%;
	width: 100%;
	height: 100%;
	object-fit: cover;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	transition: transform 200ms;
	@include media-breakpoint-up(md) {
		width: 80%;
		height: 80%;
	}

	&.stack-card:nth-last-child(n + 4) {
		--y: calc(-50% + -40px);
		--x: calc(-50% + 40px);
		transform: translate(var(--x), var(--y));
		box-shadow: 0 0 1px 1px #00000003;
		@include media-breakpoint-up(md) {
			--y: calc(-50% + -60px);
			--x: calc(-50% + 60px);
		}
	}
	&.stack-card:nth-last-child(3) {
		--y: calc(-50% + -20px);
		--x: calc(-50% + 20px);
		transform: translate(var(--x), var(--y));
		@include media-breakpoint-up(md) {
			--y: calc(-50% + -30px);
			--x: calc(-50% + 30px);
		}
	}
	&.stack-card:nth-last-child(2) {
		--y: calc(-50%);
		--x: calc(-50%);
		transform: translate(var(--x), var(--y));
	}
	&.stack-card:nth-last-child(1) {
		--y: calc(-50% + 20px);
		--x: calc(-50% + -20px);
		transform: translate(var(--x), var(--y));
		@include media-breakpoint-up(md) {
			--y: calc(-50% + 30px);
			--x: calc(-50% + -30px);
		}
	}

	@media (prefers-reduced-motion: reduce) {
		animation: none;
	}
}

// jumping arrow animation
.c-arrowdown {
	animation: bounce 1600ms infinite cubic-bezier(0.445, 0.05, 0.55, 0.95);

	@media (prefers-reduced-motion: reduce) {
		animation: none;
	}
}

// keyframe animations
@keyframes bounce {
	50% {
		transform: translateY(-15px);
	}
}

@keyframes bounce1 {
	0%,
	100% {
		transform: translateY(0);
	}
	25% {
		transform: translateY(-15%);
	}
	75% {
		transform: translateY(15%);
	}
}

@keyframes bounce2 {
	0% {
		transform: translateY(0);
	}
	50% {
		transform: translateY(-15%);
	}
	100% {
		transform: translateY(0);
	}
}

@keyframes swap {
	to {
		transform: translate3d(25vw, 0, 0) translate3d(60%, 0, 0) rotate3d(0, 0, 1, 5deg);
	}
}

@keyframes spinning {
	from {
		transform: rotate(0);
	}
	to {
		transform: rotate(360deg);
	}
}
