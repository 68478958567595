.c-slider {
	outline: 0;
}

.d-section--menu {
	.c-slider-cell {
		width: 50%;
		padding: 10px;
		animation-name: bounce2;
        animation-timing-function: ease;
        animation-duration: 2s;
        animation-iteration-count: infinite;
        transform-origin: bottom;
		@include media-breakpoint-up(lg) {
			width: 20%;
        }
		img {
			width: 100%;
			height: 100%;
			object-fit: contain;
		}
		img[src*="gif"] {
			transform: scale(1.35);
		}
	}
	.c-slider-cell:nth-of-type(odd) {
		margin-top: 5vw;
	}
	.c-slider-cell:nth-child(3n+1) {
		animation-delay: .1s;
	}
	.c-slider-cell:nth-child(3n+2) {
		animation-delay: .3s;
	}
	.c-slider-cell:nth-child(3n+3) {
		animation-delay: .5s;
	}
}

.d-section--followus {
	.c-slider {
		padding: 50px 0;
		@include media-breakpoint-up(lg) {
			padding: 100px 0;
        }
	}

	.c-slider-cell {
		margin: 0 25px;
		padding: 10px;
		width: 60%;
		padding-top: 60%;
		position: relative;
		@include media-breakpoint-up(md) {
			margin: 0 50px;
			width: 33.33%;
			padding-top: 33.33%;
        }
		@include media-breakpoint-up(lg) {
			width: 20%;
			padding-top: 20%;
        }
		img {
			position: absolute;
			width: 100%;
			height: 100%;
			object-fit: cover;
			border-radius: 18px;
			top: 0;
			left: 0;
			bottom: 0;
			right: 0;
			@include media-breakpoint-up(lg) {
				border-radius: 34px;
			}
		}
	}
	.c-slider-cell:nth-of-type(even) {
		transform: rotate(5deg);
	}
	.c-slider-cell:nth-of-type(odd) {
		margin-top: 5vw;
		transform: rotate(-5deg);
	}
}