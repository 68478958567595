.c-menu-wrapper {
	display: flex;
	align-items: center;
	height: 100%;
	width: 100%;
	@include media-breakpoint-up(lg) {
		margin-left: 28px;
	}
	@include media-breakpoint-down(md) {
		justify-content: flex-end;
	}
}

.c-menu {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	padding: 120px 10vw 32px 5vw;
	position: fixed;
	width: 50vw;
	top: 0;
	left: 0;
	height: 100vh;
	height: 100dvh;
	transform: translateX(-100%);
	transition: transform 0.4s ease 0s, visibility 0.1s linear 0.4s;
	z-index: 9;

	@include media-breakpoint-down(md) {
		padding: 100px 20vw 32px 10vw;
		width: 90vw;
	}

	@include media-breakpoint-down(sm) {
		width: 98vw;
	}

	&::before {
		content: '';
		position: absolute;
		background-color: $color-bg-base;
		height: 100%;
		width: 80%;
		top: 0;
		left: 0;
	}

	&::after {
		content: '';
		position: absolute;
		background-image: url('../images/wave_menu.svg');
		background-size: cover;
		background-repeat: no-repeat;
		height: 105%;
		width: 15vw;
		top: -5px;
		right: 0;

		@include media-breakpoint-down(md) {
			width: 20vw;
		}
		@include media-breakpoint-down(sm) {
			width: 32vw;
		}
	}

	&.is-active {
		transform: translateX(0);
		transition: transform 0.4s ease 0.1s, visibility 0.1s linear 0s, opacity 0.1s linear 0s;
		pointer-events: all;
	}

	ul {
		@extend .reset-list;
		display: flex;
		row-gap: 30px;
		flex-direction: column;
		position: relative;
		height: 100%;
		z-index: 999;
	}
}

.c-menu .menu-item {
	&:nth-of-type(1) {
		@include media-breakpoint-down(sm) {
			width: 180px;
		}

		@include media-breakpoint-up(lg) {
			width: 250px;
		}

		@include media-breakpoint-up(xl) {
			width: 300px;
		}
	}
	a {
		display: inline-block;
		font-size: 3rem;
		font-family: $font-family-base;
		font-weight: 400;
		line-height: 1.2;
		text-decoration: none;
		color: $color-secondary;
		text-transform: uppercase;
		@include focus-visible($color-secondary);

		@include media-breakpoint-up(md) {
			font-size: 4rem;
		}

		@include media-breakpoint-up(xl) {
			font-size: 5rem;
		}
	}
}

.menu-item--bottom {
	position: absolute;
	bottom: 32px;
	color: $color-highlight;
	display: flex;
	align-items: center;
	font-size: 2.2rem;
	text-transform: uppercase;
	@include media-breakpoint-down(lg) {
		flex-direction: column;
		align-items: flex-start;
	}

	.text {
		white-space: nowrap;
	}

	.button {
		@include button($color-primary, $color-highlight);
		display: flex !important;
		height: 50px !important;
		padding: 0 25px !important;
		margin: 0 0 0 25px;
		@include media-breakpoint-down(lg) {
			margin: 10px 0 0 0;
			font-size: 1.8rem;
		}
	}
}
