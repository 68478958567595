.modal-outer {
	background-color: rgba(#0d0956, 0.3);
	position: fixed;
	height: 100vh;
	width: 100vw;
	top: 0;
	left: 0;
	z-index: 999999;
	display: flex;
	opacity: 0;
	visibility: hidden;
	pointer-events: none;
	transition: opacity 300ms;
	cursor: pointer;
}

.modal-outer.is-active {
	opacity: 1;
	visibility: visible;
	pointer-events: all;
}

.modal-inner {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 100%;
	padding: 5vw;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	cursor: default;
	position: relative;
	@include media-breakpoint-up(sm) {
		width: 80%;
		padding: 200px 100px;
	}
	@include media-breakpoint-up(md) {
		width: 70%;
	}
	.modal-inner__bg {
		position: absolute;
		width: 100%;
		height: 100%;
		object-fit: contain;
		z-index: 9;
		@include media-breakpoint-down(md) {
			width: 150%;
			transform: rotate(-90deg);
		}
	}
}

.popup__inner {
	position: relative;
	z-index: 99;
}

.btn-modal {
	border: none;
	display: inline-block;
	cursor: pointer;
	background-color: transparent;
	position: absolute;
	top: 20px;
	right: 20px;
	z-index: 9999;
	@include focus-visible($color-primary);
	transition: transform 300ms ease-in-out;
	&:hover {
		transform: scale(1.1);
	}
}

.btn-modal__inner {
	display: block;
	position: relative;
	padding: 10px;
	width: 38px;
	height: 38px;
	background-image: url('../../dist/images/icon_close.svg');
	background-size: contain;
	background-repeat: no-repeat;
	background-position: center;
	@include media-breakpoint-up(md) {
		width: 50px;
		height: 50px;
	}
}
